.TileView {
    padding: 24px;
}

.TileView .title {
    display: block;
    font-size: 32px;
    color: #222;
    text-align: center;
}

.TileView .summary {
    display: block;
    font-style: italic;
    font-size: 20px;
    text-align: center;
    margin: 18px;
    color: #222;

}

.TileView .content {
    display: block;
    margin-top: 12px;
    font-size: 18px;
    border-top: 3px dotted rgb(55, 106, 156);
    padding: 32px 8px 8px;
    color: #222;
}