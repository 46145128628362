.TileView {
  padding: 24px;
}

.TileView .title {
  color: #222;
  text-align: center;
  font-size: 32px;
  display: block;
}

.TileView .summary {
  text-align: center;
  color: #222;
  margin: 18px;
  font-size: 20px;
  font-style: italic;
  display: block;
}

.TileView .content {
  color: #222;
  border-top: 3px dotted #376a9c;
  margin-top: 12px;
  padding: 32px 8px 8px;
  font-size: 18px;
  display: block;
}

/*# sourceMappingURL=index.1f92434b.css.map */
